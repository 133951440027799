import React from 'react'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Layout,
  BannerSmall,
  TextBox,
  BannerLinks,
  BlogOverview
} from '../../components'

import {
  faFacebookSquare,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import BannerImage from '../../assets/projektuebersicht.jpg'
import beratung from '../../assets/bannerlinks/verlaessliche.beratung.jpg'
import laptop from '../../assets/bannerlinks/bannerbox.eglo.pim.jpg'

import Farbverlauf from '../../assets/verlauf.jpg'
import SandsofTime from '../../assets/sandsoftime.jpg'
import Mouse from '../../assets/mouse.opt.gif'
import HandyNav from '../../assets/handynavigation.jpg'
import HowIsYourDay from '../../assets/splitscreens.jpg'
import UserFirst from '../../assets/blog.userfirst.jpg'
import matomo_m from '../../assets/matomologo_name.png'
import ie_grave from '../../assets/internet-explorer-gravestone-overlay.png'
import anja_thumbnail from '../../assets/anja-friedensdorf-interview.jpg'
import hwLogo3D from '../../assets/hwlogo/Hw3DLogoScreenshot.png'
import exitSign from '../../assets/exit_sign.jpg'
import error from '../../assets/error.jpg'
import projectmanagement from '../../assets/projectmanagement.jpg'
import thinking from '../../assets/thinking.jpg'
import analytics from '../../assets/analytics.jpg'

const boxes = [
  {
    text: "Let's do it!",
    image: beratung,
    link: '/kontakt',
    linkText: 'Kontakt'
  },
  {
    text: 'Join the team!',
    image: laptop,
    link: '/karriere',
    linkText: 'Mehr lesen'
  }
]

const blogs = [
  {
    tag: 'Technologie',
    headline: 'KI im Backend',
    subheadline: 'Der holzweg KI Zauberstab',
    backgroundImage: BannerImage,
    readingTime: null,
    blackColor: true,
    link: '/blog/ki-im-backend'
  },
  {
    tag: 'Technologie',
    headline: 'Interaktive 3D-Modelle',
    backgroundImage: hwLogo3D,
    readingTime: null,
    blackColor: true,
    link: '/blog/interaktive-3d-modelle'
  },
  {
    tag: 'ui',
    headline: 'Exit Intent Layer',
    backgroundImage: exitSign,
    readingTime: null,
    blackColor: true,
    link: '/blog/exit-intent-layer'
  },
  {
    tag: 'ui',
    headline: 'Custom Error Pages',
    backgroundImage: error,
    readingTime: null,
    blackColor: true,
    link: '/blog/custom-error-pages'
  },
  {
    tag: 'Karriere',
    headline: 'Interview zum Girls Day 2022',
    backgroundImage: anja_thumbnail,
    readingTime: null,
    whiteColor: true,
    link: '/blog/girlsday-2022'
  },
  {
    tag: 'Technologie',
    headline: 'Der Internet Explorer wird eingestellt',
    backgroundImage: ie_grave,
    readingTime: null,
    whiteColor: true,
    link: '/blog/internet-explorer-death'
  },
  {
    tag: 'DSGVO',
    headline: 'matomo statt Google Analytics!',
    subheadline: 'Meiden auch Sie hohe Bußgeldstrafen',
    backgroundImage: matomo_m,
    readingTime: null,
    link: '/blog/matomo_statt_google/'
  },
  {
    tag: 'ui',
    tag: 'ui',
    headline: 'User first!',
    subheadline: 'weil mobile-first ist desktop-worst',
    backgroundImage: UserFirst,
    readingTime: 2,
    whiteColor: true,
    link: '/blog/user-first/'
  },
  {
    tag: 'SEO',
    headline: '95% Uplift mit SEO',
    subheadline: 'Reichweite der Webseite maximieren',
    backgroundImage: analytics,
    readingTime: 5,
    link: '/blog/mittels-seo-zur-besten-platzierung'
  },
  {
    tag: 'ux',
    tag: 'ux',
    headline: 'Micro Interaction',
    subheadline: 'Verbesserung der User Experience',
    backgroundImage: Mouse,
    readingTime: 2,
    link: '/blog/micro-interaction/'
  },

  {
    tag: 'ui',
    tag: 'ui',
    headline: 'Mobiles Menü ganz unten',
    subheadline: 'Daumen brauchen neue Menüführung',
    backgroundImage: HandyNav,
    readingTime: 5,
    whiteColor: true,
    link: '/blog/mobilenavigationganzunten/'
  },
  // {
  //   tag: 'Technologie',
  //   headline: 'DSGVO - einfach!',
  //   subheadline: 'DSGVO Checkliste zum Abhaken',
  //   backgroundImage: null,
  //   readingTime: 2,
  //   link: '/blog/dsgvo-einfach/'
  // },
  {
    tag: 'Projektmanagement',
    headline: 'Ticketsystem',
    subheadline: 'Strukturierte Kommunikation während des Projektes',
    backgroundImage: projectmanagement ,
    readingTime: 2,
    whiteColor: true,
    link: '/blog/customer-portal/'
  },
  {
    tag: 'ui',
    tag: 'ui',
    headline: 'Gradient your life',
    subheadline: 'Farbverläufe und Transitions',
    backgroundImage: Farbverlauf,
    readingTime: 2,
    whiteColor: true,
    link: '/blog/gradient-your-life/'
  },
  {
    tag: 'ui',
    tag: 'ui',
    headline: 'Bild-befüllter Text',
    subheadline: 'Stylish.Punkt.',
    backgroundImage: SandsofTime,
    readingTime: 2,
    link: '/blog/bild-befuellter-text/'
  },
  {
    tag: 'Technologie',
    headline: 'Cross-Testing',
    subheadline: 'Wie funktioniert das?',
    backgroundImage: thinking,
    readingTime: 2,
    link: '/blog/cross-testing/'
  },
  {
    tag: 'ui',
    tag: 'ui',
    headline: 'Split Screens',
    subheadline: 'Dualität mit Style Faktor',
    backgroundImage: HowIsYourDay,
    readingTime: 2,
    whiteColor: true,
    link: '/blog/split-screens/'
  }
]

const Blog = () => (
  <Layout>
    <Helmet
      title="Blogeinträge von holzweg"
      meta={[
        {
          name: 'description',
          content:
            'Auf dieser Seite sehen Sie eine Übersicht über die Blogeinträge von holzweg. Es gibt spannende Themen in den Bereichen Design, Technologie und Strategie.'
        },
        {
          name: 'keywords',
          content:
            'Blog holzweg, Webagentur holzweg, Blog, Blogposts, Postings holzweg, Neu holzweg'
        },
        {
          property: 'og:description',
          content:
            'Auf dieser Seite sehen Sie eine Übersicht über die Blogeinträge von holzweg. Es gibt spannende Themen in den Bereichen Design, Technologie und Strategie.'
        },
        {
          property: 'og:image',
          content:
            'https://www.holzweg.com/static/projektuebersicht-3b0d0cca7e33df5e55e7b68d779cc633.jpg'
        }
      ]}
    />
    <div>
      <BannerSmall image={BannerImage} title="Auf dem holzweg zur Zukunft" />
      <TextBox
        title="Alle Blogbeiträge:"
        text="Willkommen auf unserem Blog! Tauche ein in die Welt von Design, Technologie und Strategie. Wir bieten dir aktuelle Trends, tiefgehende Analysen und inspirierende Erfolgsgeschichten, die dein Wissen erweitern und dich auf dem Laufenden halten. Unsere Experten teilen regelmäßig ihre Erkenntnisse und geben dir wertvolle Einblicke in die neuesten Entwicklungen. Ob du nach innovativen Ideen, praktischen Tipps oder fundierten Hintergrundinformationen suchst – hier bist du richtig. Entdecke unsere neuesten Blogposts und lass dich inspirieren! Bleib informiert und verpasse keine Updates mehr."
      />
      <BlogOverview title="" blogs={blogs} />
      <TextBox
        title="Walk with us on the holzweg"
        text={
          <span>
            <p>
              Ready für uns? Dann kontaktiere uns zu einem unverbindlichen
              Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn
              der holzweg nach Traumjob-Garantie klingt, dann schaue bei den
              Stellenangeboten rein oder besuche uns in den sozialen Medien!
            </p>
            <p>
              <a
                href="https://www.facebook.com/holzwegwebagentur"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#ec6318' }}
              >
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.linkedin.com/company/holzweg-e-commerce-solutions"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#f08607' }}
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.youtube.com/user/holzwegagentur"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#f4a301' }}
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </p>
          </span>
        }
      />
      <BannerLinks boxes={boxes} />
    </div>
  </Layout>
)

export default Blog
